import axios from 'axios'
import { statsFilter, rankedModule, rankedContent } from '../models/stats.model'
import { RankedTag } from '@bertazzoni/common/src/models/tag.model'
import { Content } from '../models/content.model'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/stats'

class StatsService {
  async moduleCompleted(id: string, filter?: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/module/${id}/completed`, filter)
    return response.data.completedModuleDistinctUser
  }
  async allModulesCompleted(filter: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/allModules/completed`, filter)
    return response.data.completedModuleDistinctUser
  }
  async allModulesCountByLanguage(filter: statsFilter): Promise<{ _id: string; count: number }[]> {
    const response = await axios.post(`${API_URL}/allModules/countByLanguage`, filter)
    return response.data
  }
  async allModulesCountAvailable(filter: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/allModules/countAvailable`, filter)
    return response.data[0].availableModules
  }
  async moduleAvgScore(id: string, filter?: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/module/${id}/averageScore`, filter)
    return Math.round(response.data.averageScoreRate * 100)
  }
  async allModulesAvgScore(filter: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/allModules/averageScore`, filter)
    return Math.round(response.data.averageScoreRate * 100)
  }
  async moduleQuestionsAvg(id: string, filter?: statsFilter): Promise<number[]> {
    const response = await axios.post(`${API_URL}/module/${id}/questionsAverage`, filter)
    return response.data.average.map((avg: number) => Math.round(avg * 100))
  }
  async usersActivity(
    filter: statsFilter
  ): Promise<{ active: number; inactive: number; _id: string }> {
    const response = await axios.post(`${API_URL}/users/activity`, filter)
    return response.data
  }
  async usersTotal(filter: statsFilter): Promise<number> {
    const response = await axios.post(`${API_URL}/users/total`, filter)
    return response.data.total
  }
  async rankingModules(filter?: statsFilter): Promise<rankedModule[]> {
    const response = await axios.post(`${API_URL}/ranking/modules`, filter)
    response.data.map(
      (rankedModule: rankedModule) =>
        (rankedModule.scoreAverage = Math.round(rankedModule.scoreAverage * 100))
    )
    return response.data
  }
  async rankingContents(filter?: statsFilter): Promise<rankedContent[]> {
    const response = await axios.post(`${API_URL}/ranking/contents`, filter)
    return response.data
  }
  async contentViews(id: string, filter?: statsFilter): Promise<Content> {
    const response = await axios.post(`${API_URL}/contents/${id}/views`, filter)
    return response.data
  }
  async rankingTags(filter?: statsFilter): Promise<RankedTag[]> {
    const response = await axios.post(`${API_URL}/ranking/tags`, filter)
    return response.data
  }
}

export default new StatsService()
