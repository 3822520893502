



































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import KeyElementsView from '@bertazzoni/back/components/KeyElementsView.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import { Content, TypeContent } from '@bertazzoni/common/src/models/content.model'
import StatsService from '@bertazzoni/common/src/services/StatsService'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'

@Component({
  components: { Loader, DatePicker, KeyElementsView, PaginatedTable, TagList, Icons }
})
export default class ContentConsultation extends Vue {
  private currentContent: Content
  private pageLoaded = false
  private typeContent = TypeContent

  async created(): Promise<void> {
    this.currentContent = await ContentService.findOne(this.$route.params.id)
    await this.getStats()
    this.pageLoaded = true
  }
  updated() {
    if (this.currentContent.type === this.typeContent.ppt) {
      this.currentContent.srcURI = this.currentContent.srcURI.replace('350px', '750px')
      this.currentContent.srcURI = this.currentContent.srcURI.replace('286px', '586px')
      document.getElementById('content-ppt').innerHTML = this.currentContent.srcURI
    }
  }
  async getStats(): Promise<void> {
    try {
      const content: Content = await StatsService.contentViews(this.$route.params.id)
      this.currentContent.views = content.views
    } catch {
      this.currentContent.views = 0
      EventBus.$emit('close', 'all')
    }
  }
}
