











































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class KeyElementsView extends Vue {
  @Prop({ required: true }) keyElements!: Array<string>
}
